<template>
  <div>
    <div
      v-b-modal.select-serviceType
      tabindex="-1"
      class="service-card shadow-sm"
      :class="servicePaymentMode === true ? 'active-selection' : null"
      @click="SET_SERVICE_PAYMENT(true)"
    >
      <i class="fa fa-2x fa-cart-plus mb-1" />
      Pago de servicios y TAG 3
    </div>
    <!-- Show the services type -->
    <b-modal
      id="select-serviceType"
      title="Selecciona una opción"
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      ok-only
      @ok="resetData('select-serviceType')"
    >
      <b-row class="d-flex justify-content-center w-100">
        <b-col
          v-for="(service, key) in servicePaymentsProducts"
          :key="`service-${key}`"
          sm="auto"
        >
          <b-button
            v-for="(typeService, serviceName) in service"
            :key="`service-${serviceName}`"
            v-b-modal.select-service-supplier
            :variant="serviceData === typeService ? 'info' : 'primary'"
            class="m-1"
            block
            @click="serviceData = typeService"
          >
            {{ serviceName }}
          </b-button>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div class="d-flex justify-content-between w-100">
          <b-button
            variant="outline-danger"
            @click="resetData('select-serviceType')"
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- Show the suppliers for the service selected -->
    <b-modal
      id="select-service-supplier"
      title="Selecciona una opción"
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      ok-only
      ok-title="Cancelar"
      ok-variant="outline-danger"
      @ok="resetData('select-service-supplier')"
    >
      <b-row class="d-flex justify-content-center w-100">
        <b-col sm="auto">
          <b-button
            v-for="(supplierService, supplierServiceName) in serviceData"
            :key="`service-${supplierServiceName}`"
            v-b-modal.select-option-service
            :variant="
              supplierServiceData === supplierService ? 'info' : 'primary'
            "
            class="m-1"
            block
            @click="supplierServiceData = supplierService"
          >
            {{ supplierServiceName }}
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <!-- Show the options for the service supplier selected -->
    <b-modal
      v-if="supplierServiceData"
      id="select-option-service"
      title="Selecciona una opción y da click en continuar"
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    >
      <p style="font-size: large" class="text-center text-warning">
        Este servicio acepta pagos {{ supplierServiceData[0].payment_type }}
      </p>
      <b-row class="d-flex justify-content-center w-100">
        <b-col>
          <b-button
            v-for="option in supplierServiceData"
            :key="`option-${option.id}`"
            :variant="selectedOption === option ? 'info' : 'primary'"
            block
            class="my-1"
            size="lg"
            @click="selectedOption = option"
          >
            {{ option.carrier_name }} - {{ option.product_name }}
          </b-button>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button
          variant="outline-danger"
          @click="resetData('select-option-service')"
        >
          Cancelar
        </b-button>
        <b-button
          :disabled="selectedOption === null"
          :variant="selectedOption === null ? 'outline-primary' : 'success'"
          @click="fectchAndOpenModal()"
        >
          OK
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="amount-modal"
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    >
      <validation-observer ref="accountRules" tag="form">
        <b-row v-if="selectedProduct" class="w-100">
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">¿Cuánto vas a pagar?</h5>
            <small class="text-warning">
              La cantidad mínima es de ${{
                selectedProduct.amount_min | money
              }}
              y máxima de ${{
                (Math.min(Number(selectedProduct.amount_max), 8000) -
                  Number(selectedProduct.product_u_fee))
                  | money
              }}
              <!-- Since we use CoDi as Payment, it accepts maximum MXN 8000 -->
            </small>
          </b-col>
        </b-row>
        <b-row class="w-100">
          <b-col>
            <b-form-group label-for="v-ammount">
              <validation-provider
                #default="{ errors }"
                name="Créditos"
                rules="required"
              >
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text
                      :state="errors.length > 0 ? false : null"
                    >
                      <strong :class="errors.length > 0 ? 'text-danger' : null"
                        >$</strong
                      >
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    id="v-ammount"
                    v-model="emida_product.amount"
                    type="text"
                    :state="
                      errors.length > 0
                        ? false
                        : null ||
                          (Number(emida_product.amount) <=
                            Number(selectedProduct.amount_max) -
                              Number(selectedProduct.product_u_fee) &&
                            Number(emida_product.amount) >=
                              Number(selectedProduct.amount_min))
                    "
                    placeholder="25"
                    size="lg"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>

      <template #modal-footer>
        <b-button variant="outline-danger" @click="resetData('amount-modal')">
          Cancelar
        </b-button>
        <b-button
          :disabled="
            !(
              Number(emida_product.amount) <=
                Number(selectedProduct.amount_max) -
                  Number(selectedProduct.product_u_fee) &&
              Number(emida_product.amount) >= Number(selectedProduct.amount_min)
            )
          "
          :variant="
            !(
              Number(emida_product.amount) <=
                Number(selectedProduct.amount_max) -
                  Number(selectedProduct.product_u_fee) &&
              Number(emida_product.amount) >= Number(selectedProduct.amount_min)
            )
              ? 'outline-primary'
              : 'success'
          "
          @click="validationForm()"
        >
          OK
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="charge-modal"
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    >
      <b-row class="d-flex justify-content-center w-100">
        <b-col v-if="selectedProduct !== null" class="py-4">
          <div class="display-1 text-success text-center">
            ${{
              (
                Number(emida_product.amount) +
                Number(selectedProduct.product_u_fee)
              ).toFixed(2)
            }}
          </div>
          <h4 class="text-center">Total a pagar por la transacción</h4>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div class="d-flex justify-content-between w-100">
          <b-button variant="outline-danger" @click="resetData('charge-modal')">
            Cancelar
          </b-button>
          <b-button
            variant="success"
            @click="$bvModal.show('complete-codi-payment-for-services')"
          >
            Continuar
          </b-button>
        </div>
      </template>
    </b-modal>
    <!-- Select Payment method -->
    <b-modal
      id="complete-codi-payment-for-services"
      title="Pago con CoDi"
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    >
      <validation-observer ref="accountCelRules" tag="form">
        <b-row class="w-100">
          <b-col
            class="
              mb-2
              d-flex
              flex-column
              justify-content-center
              align-items-center
            "
            cols="12"
          >
            <b-img
              :src="require('@/assets/images/logo/CODI.svg')"
              width="150"
            />
            <h5 class="mb-0">Número de teléfono</h5>
            <small class="text-muted"
              >Agrega tu número de teléfono para confirmar la operación</small
            >
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="v-cel_number">
              <validation-provider
                #default="{ errors }"
                name="Celular"
                rules="required|digits:10"
              >
                <b-form-input
                  id="v-cel_number"
                  v-model="emida_product.cel_number"
                  placeholder="Celular a 10 dígitos"
                  size="lg"
                  type="number"
                  class="text-center"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <b-col cols="12" class="d-flex justify-content-center align-items-center">
        <b-button v-b-modal.codi-info-for-services variant="link">
          ¿Cómo activar CoDi? <em class="fas fa-info-circle" />
        </b-button>
      </b-col>
      <template #modal-footer>
        <b-button
          variant="outline-danger"
          @click="resetData('complete-codi-payment-for-services')"
        >
          Cancelar
        </b-button>
        <div>
          <b-button
            :disabled="
              !(
                emida_product.cel_number &&
                String(emida_product.cel_number).length === 10
              )
            "
            :variant="
              !(
                emida_product.cel_number &&
                String(emida_product.cel_number).length === 10
              )
                ? 'outline-primary'
                : 'success'
            "
            @click="handleOpenFinishModal()"
          >
            OK
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      id="codi-info-for-services"
      title="Conoce como activar CoDi"
      ok-only
      ok-title="Cancelar"
      ok-variant="secondary"
      @ok="$bvModal.show('complete-codi-payment-for-services')"
    >
      <b-row class="d-flex justify-content-center w-100">
        <b-col sm="auto" class="d-flex flex-column">
          <b-button
            href="https://youtu.be/Ywx-aXuqqUI"
            target="_blank"
            variant="primary"
            class="mb-1"
          >
            BBVA
          </b-button>
          <b-button
            href="https://youtu.be/UP1RvwyvmLA"
            target="_blank"
            variant="primary"
            class="mb-1"
          >
            Citibanamex
          </b-button>
          <b-button
            href="https://youtu.be/pfAvXmyZ8xQ"
            target="_blank"
            variant="primary"
            class="mb-1"
          >
            Santander
          </b-button>
          <b-button
            href="https://youtu.be/ELo20ro2GyE"
            target="_blank"
            variant="primary"
            class="mb-1"
          >
            Banorte
          </b-button>
          <b-button
            href="https://www.scotiabank.com.mx/servicios-bancarios/codi.aspx"
            target="_blank"
            variant="primary"
            class="mb-1"
          >
            Scotiabank
          </b-button>
          <b-button
            href="https://www.hsbc.com.mx/content/dam/hsbc/mx/documents/digital/hsbc-mexico/registro_codi_hsbc_mexico.pdf"
            target="_blank"
            variant="primary"
            class="mb-1"
          >
            HSBC
          </b-button>
          <b-button
            href="https://www.banregio.com/codi.php"
            target="_blank"
            variant="primary"
            class="mb-1"
          >
            Banregio
          </b-button>
          <b-button
            href="https://www.bancoppel.com/modal_bcopp/guia_codi_activacion_movil.html"
            target="_blank"
            variant="primary"
            class="mb-1"
          >
            Bancoppel
          </b-button>
          <b-button
            href="https://www.bancoazteca.com.mx/conocenos/uso-de-codi.html"
            target="_blank"
            variant="primary"
            class="mb-1"
          >
            Banco Azteca
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="scan-barcode"
      title="Ingresa código de barras de tu recibo"
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      ok-only
      cancel-title="Continuar"
      cancel-variant="success"
      ok-title="Cancelar"
      ok-variant="outline-danger"
      @ok="resetData('scan-barcode')"
      @cancel="finishImageUpload()"
    >
      <div class="takePhoto">
        <br />
        <p class="d-flex flex-column">
          <span class="text-warning">
            ¿Cómo quieres ingresar el código de barras de tu recibo?
          </span>
          <br />
          <b-row>
            <b-col cols="6">
              <b-button
                variant="outline-warning"
                @click="$bvModal.show('select-account-and-finish-transaction')"
              >
                Manualmente
              </b-button>
            </b-col>
            <!--<b-col
              cols="6"
            >
              <label
                for="uploadImage"
                class="btn btn-success"
              >
                Tomar foto
              </label>
              <input
                id="uploadImage"
                ref="fileInput"
                type="file"
                style="display: none;"
                @input="pickFile"
              >
            </b-col>
            -->
            <b-col cols="6">
              <b-button
                variant="primary"
                @click="$bvModal.show('scanner-payment-slip')"
              >
                Escanear
                <i class="fas fa-barcode" />
              </b-button>
            </b-col>
            <!--<div
              class="imagePreviewWrapper"
              :style="{ 'background-image': `url(${previewImage})` }"
              @click="selectImage"
            />
            -->
          </b-row>
        </p>
      </div>
    </b-modal>
    <b-modal id="account-id-scan">
      <StreamBarcodeReader @decode="onDecodePaymentSlipAccountId" />
    </b-modal>
    <b-modal id="reference-id-scan">
      <StreamBarcodeReader @decode="onDecodePaymentSlipReferenceId" />
    </b-modal>
    <b-modal id="scanner">
      <StreamBarcodeReader @decode="onDecode" />
    </b-modal>
    <b-modal
      id="try-again"
      title="Intenta nuevamente"
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      ok-only
      ok-title="Finalizar"
      ok-variant="warning"
      @ok="$bvModal.show('scan-barcode')"
    >
      <span style="font-size: large" class="text-warning">
        Por desgracia el código de barras no pudo ser leído correctamente. Si
        hay códigos QR, tápalos con tu mano. Intenta nuevamente.
      </span>
    </b-modal>
    <b-modal
      id="verify-barcode"
      title="Verifica que el código de barras sea igual que en tu recibo"
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      ok-title="Finalizar"
      ok-variant="success"
      cancel-title="Regresar"
      cancel-variant="outline-danger"
      @ok="verifyAndSubmit()"
      @cancel="$bvModal.show('scan-barcode')"
    >
      <div class="w-100">
        <b-row class="w-100">
          <b-col
            class="
              mb-2
              d-flex
              flex-column
              justify-content-center
              align-items-center
            "
          >
            <p class="d-flex flex-column">
              <span style="font-size: large" class="text-warning">
                ¿El código de barras es el correcto?
              </span>
              <br />
              <span style="font-size: large" class="text-success">
                {{ emida_product.account_id }}
              </span>
            </p>
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <b-modal
      v-if="selectedProduct"
      id="select-account-and-finish-transaction"
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      hide-footer
    >
      <b-row class="w-100">
        <b-col>
          <b-tabs content-class="mt-3">
            <b-tab
              v-for="(reference, key) in selectedProduct.references"
              :key="`reference-${key}`"
              :title="reference.reference_name"
            >
              <b-card-text>
                <p class="d-flex flex-column">
                  <span class="text-muted"> Información: </span>
                  {{ reference.tool_tip }}
                </p>
                <validation-observer
                  #default="{ handleSubmit }"
                  :ref="`accountFormObserver-${key}`"
                >
                  <!-- Account Form -->
                  <b-form
                    @submit.prevent="handleSubmit(finishTransaction(key))"
                    @reset.prevent="resetForm"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="account"
                      rules="required"
                    >
                      <b-form-group
                        :label="reference.reference_name"
                        label-for="account"
                      >
                        <b-input-group>
                          <b-form-input
                            id="account"
                            v-model="emida_product.account_id"
                            type="number"
                            :state="
                              errors.length > 0
                                ? false
                                : null ||
                                  (String(emida_product.account_id).length >=
                                    Number(reference.length_min) &&
                                    String(emida_product.account_id).length <=
                                      Number(reference.length_max))
                            "
                            autocomplete="off"
                            trim
                          />
                          <b-button
                            v-if="showBarcoScanner"
                            variant="warning"
                            @click="$bvModal.show('account-id-scan')"
                          >
                            <i class="fas fa-barcode" style="font-size: 1rem" />
                          </b-button>
                        </b-input-group>
                        <b-form-invalid-feedback>
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <validation-provider
                      #default="{ errors }"
                      name="account"
                      rules="required"
                    >
                      <b-form-group
                        :label="reference.reference_name"
                        label-for="confirmation"
                      >
                        <!-- Validate phone number -->
                        <b-input-group>
                          <b-form-input
                            id="confirmation"
                            v-model="registrationValidation"
                            type="number"
                            :state="
                              errors.length > 0
                                ? false
                                : null ||
                                  (String(registrationValidation).length >=
                                    Number(reference.length_min) &&
                                    String(registrationValidation).length <=
                                      Number(reference.length_max))
                            "
                            autocomplete="off"
                            trim
                          />
                          <b-button
                            v-if="showBarcoScanner"
                            variant="warning"
                            @click="$bvModal.show('reference-id-scan')"
                          >
                            <i class="fas fa-barcode" style="font-size: 1rem" />
                          </b-button>
                        </b-input-group>
                        <b-form-invalid-feedback>
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                    <b-row class="d-flex justify-content-between mt-2">
                      <span style="color: orange">
                        Asegurate que la información proporcionada sea correcta,
                        no hay devoluciones en este producto
                      </span>
                    </b-row>
                    <b-row class="d-flex justify-content-between mt-2">
                      <b-col sm="auto" cols="6">
                        <b-button
                          variant="outline-danger"
                          @click="
                            resetData('select-account-and-finish-transaction')
                          "
                        >
                          Cancelar
                        </b-button>
                      </b-col>
                      <b-col sm="auto" cols="6">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="success"
                          class="mr-2"
                          type="submit"
                          :disabled="loading"
                        >
                          <b-spinner v-if="loading" small />
                          Confirmar
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </validation-observer>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios";
import { StreamBarcodeReader } from "vue-barcode-reader";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    StreamBarcodeReader,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      previewImage: null,
      min: null,
      showBarcoScanner: null,
      max: null,
      base64Img: null,
      loading: false,
      userData: JSON.parse(localStorage.getItem("userData")),
      serviceData: null,
      supplierServiceData: null,
      selectedOption: null,
      selectedProduct: null,
      emida_product: {
        product_id: null,
        account_id: null,
        store_id: null,
        payment_type: "codi",
        store_clerk_id: null,
        cel_number: null,
        amount: null,
        order_payments_attributes: [
          {
            payment_type: null,
            amount: null,
          },
        ],
      },
      registrationValidation: null,
      params: {
        image: null,
        max_length: null,
        min_length: null,
      },
    };
  },
  computed: {
    ...mapGetters("stores", ["currentStore"]),
    ...mapGetters("pos", ["servicePaymentMode"]),
    ...mapGetters("emidaProducts", ["servicePaymentsProducts"]),
  },

  methods: {
    ...mapMutations("pos", ["SET_SERVICE_PAYMENT", "SET_SUPPLIER"]),
    ...mapActions("emidaProducts", ["fetchEmidaProduct", "purchaseTypeB"]),
    fectchAndOpenModal() {
      this.fetchEmidaProduct(this.selectedOption.id).then((response) => {
        this.selectedProduct = response;

        if (response.amount === 0 || response.amount === "0") {
          this.emida_product.amount = null;
        } else {
          this.emida_product.amount = response.amount;
        }

        // eslint-disable-next-line no-unused-expressions
        Number(response.amount) === 0 || response.amount === null
          ? this.$bvModal.show("amount-modal")
          : this.$bvModal.show("charge-modal");
      });
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
            this.$bvModal.show("charge-modal");
          } else {
            reject();
          }
        });
      });
    },
    resetData(id) {
      this.SET_SERVICE_PAYMENT(false);
      this.serviceData = null;
      this.supplierServiceData = null;
      this.selectedOption = null;
      this.selectedProduct = null;
      this.registrationValidation = null;
      this.amountToPay = 0;
      this.emida_product = {
        product_id: null,
        account_id: null,
        cel_number: null,
        amount: null,
        order_payments_attributes: [
          {
            payment_type: null,
            amount: null,
          },
        ],
      };
      this.$bvModal.hide(id);
    },
    eliminarDiacriticos(text) {
      return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
    selectPaymentMethodForServices() {
      this.$bvModal.hide("complete-codi-payment-for-services");
      this.$bvModal.show("charge-modal");
    },
    finishTransaction(reference) {
      this.$refs[`accountFormObserver-${reference}`][0]
        .validate()
        .then((success) => {
          if (success) {
            this.onSubmit();
          }
        });
    },
    onSubmit() {
      if (this.emida_product.account_id === this.registrationValidation) {
        this.loading = true;
        this.emida_product.product_id = this.selectedProduct.product_id;
        (this.emida_product.order_payments_attributes = [
          {
            payment_type: "codi",
            amount: (
              Number(this.emida_product.amount) +
              Number(this.selectedProduct.product_u_fee)
            ).toString(),
          },
        ]),
          this.purchaseTypeB(this.emida_product)
            .then((response) => {
              if (response.status === 202) {
                this.$swal({
                  title: "¡Compra pendiente!",
                  text: response.data.messages[0],
                  icon: "warning",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              } else {
                this.$swal({
                  title: "Cobro solicitado!",
                  text: "En unos momentos más llegará una notificación en tu aplicación bancaria. Autoriza el cobro en tu celular para poder procesar tu compra",
                  icon: "success",
                  customClass: {
                    confirmButton: "btnx btn-primary",
                  },
                  buttonsStyling: false,
                });
              }
              this.loading = false;
              this.showBarcoScanner = false;
              this.resetData();
              this.$bvModal.hide("select-account-and-finish-transaction");
            })
            .catch((error) => {
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Error",
                  icon: "CoffeeIcon",
                  variant: "danger",
                  text: error.response.data.messages[0],
                },
              });
              this.loading = false;
              this.showBarcoScanner = false;
              this.resetData();
              this.$bvModal.hide("select-account-and-finish-transaction");
            });
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Error",
            icon: "CoffeeIcon",
            variant: "danger",
            text: "Revisa las referencias no coinciden",
          },
        });
      }
    },
    handleOpenFinishModal() {
      this.hasBarcodeInput();
      this.$bvModal.show("select-account-and-finish-transaction");
    },
    handleBarcodeReader() {
      // if ((this.selectedProduct.references.filter(item => item.reference_name.toLowerCase().includes('numero') && item.reference_name.toLowerCase().includes('cliente'))).length === 1) { //  <<< NEED TO CHANGE TO CODIGO AND BARRAS
      //  this.$bvModal.show('scan-barcode')
      // } else {
      this.$bvModal.show("select-account-and-finish-transaction");
      // }
    },
    onDecodePaymentSlipAccountId(result) {
      console.log(result);
      this.emida_product.account_id = result;
      this.$bvModal.hide("account-id-scan");
      this.$bvModal.show("select-account-and-finish-transaction");
    },
    onDecodePaymentSlipReferenceId(result) {
      console.log(result);
      this.registrationValidation = result;
      this.$bvModal.hide("reference-id-scan");
      this.$bvModal.show("select-account-and-finish-transaction");
    },
    onDecode(result) {
      console.log(result);
      this.filters.q = result;
      this.$bvModal.hide("scanner");
    },
    onDecodePaymentSlip(result) {
      console.log(result);
      this.getBarCodeLengths();
      if (result.length() >= this.min && result.length() <= this.max) {
        this.emida_product.account_id = result;
        this.registrationValidation = result;
        this.$bvModal.hide("scanner");
        this.$bvModal.show("verify-barcode");
      } else {
        this.$bvModal.show("try-again");
      }
    },
    selectImage() {
      this.$refs.fileInput.click();
    },
    pickFile() {
      const input = this.$refs.fileInput;
      const file = input.files;
      if (file && file[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
          const img = document.createElement("img");
          img.src = e.target.result;
          const canvas = document.createElement("canvas");
          const width = img.width * 0.3;
          const height = img.height * 0.3;
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);
          const dataURL = canvas.toDataURL("image/jpeg", 1);
          // console.log(dataURL.replace(/^data:image\/\w+;base64,/, ''))
          this.base64Img = dataURL.replace(/^data:image\/\w+;base64,/, "");
          // console.log(this.base64Img)
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
      }
    },
    finishImageUpload() {
      this.getBarCodeLengths();
      this.uploadImage(this.base64Img, this.min, this.max).then((response) => {
        if (
          response.barcode !==
          "Código de barras no encontrado o no es el correcto, por favor intenta de nuevo"
        ) {
          this.emida_product.account_id = response.barcode;
          this.registrationValidation = response.barcode;
          this.$bvModal.hide("scan-barcode");
          this.$bvModal.show("verify-barcode");
        } else {
          this.$swal({
            title: "¡Intenta de nuevo!",
            text: response.barcode,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    getBarCodeLengths() {
      this.selectedProduct.references
        .filter(
          (item) =>
            item.reference_name.toLowerCase().includes("codigo") &&
            item.reference_name.toLowerCase().includes("barras")
        ) // <<< NEED TO CHANGE TO CODIGO AND BARRAS
        // eslint-disable-next-line
        .forEach(function (item, index) {
          // eslint-disable-next-line
          this.max = Number(item.length_max);
          // eslint-disable-next-line
          this.min = Number(item.length_min);
          console.log(
            item.reference_name,
            item.length_max,
            item.length_min,
            index
          );
        });
    },

    hasBarcodeInput() {
      if (
        this.selectedProduct.references.filter(
          (item) =>
            item.reference_name.toLowerCase().includes("codigo") &&
            item.reference_name.toLowerCase().includes("barras")
        ).length === 1
      ) {
        //  <<< NEED TO CHANGE TO CODIGO AND BARRAS
        this.showBarcoScanner = true;
      } else {
        this.showBarcoScanner = false;
      }
    },

    uploadImage(encodedImage, min, max) {
      const URL = "https://barcode.staging.mywalleat.com/api/decode"; // <<< NEED TO CHANGE TO ACTUAL SERVER ROUTE
      // eslint-disable-next-line prefer-destructuring
      const params = {
        image: encodedImage,
        min_length: min,
        max_length: max,
      };
      return new Promise((resolve, reject) => {
        axios
          .post(URL, params)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    verifyAndSubmit() {
      this.onSubmit();
      this.$bvModal.hide("verify-barcode");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/core/colors/palette-variables.scss";

.active-selection {
  background-color: $primary !important;
}

.codi-button {
  background-color: #444c66;
}

.imagePreviewWrapper {
  width: 200px;
  height: 200px;
  display: block;
  cursor: pointer;
  margin: 0 auto 30px;
  background-size: cover;
  background-position: center center;
}
</style>
