<template>
  <div>
    <b-modal
      id="addReferenceModal"
      title="Añadir datos del alumno"
      hide-footer
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      class="p-1"
    >
      <validation-observer
        ref="accountStudentReferenceRules"
        tag="form"
        v-slot="{ invalid }"
      >
        <b-form @submit.prevent="handleAddReference()" class="p-1">
          <validation-provider
            #default="{ errors, valid }"
            name="El nombre de la persona a quien va dirigido el producto o servicio es necesario"
            rules="required"
          >
            <b-input
              v-model="bookReference.name"
              :state="errors.length > 0 ? false : valid ? true : null"
              placeholder="Nombre completo"
              class="mb-1"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <validation-provider
            #default="{ errors, valid }"
            name="El email de la persona a quien va dirigido el producto o servicio es necesario"
            rules="required|email"
            v-if="currentStore.store_type === 'corporate_education'"
          >
            <b-input
              v-model="bookReference.email"
              :state="errors.length > 0 ? false : valid ? true : null"
              placeholder="Correo"
              class="mb-1"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <validation-provider
            #default="{ errors, valid }"
            name="El numero telefonico de la persona a quien va dirigido el producto o servicio es necesario"
            rules="required|phone_number"
            v-if="currentStore.store_type === 'corporate_education'"
          >
            <b-input
              v-model="bookReference.contact"
              :state="errors.length > 0 ? false : valid ? true : null"
              placeholder="Numero de contacto"
              maxlength="10"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <div class="d-flex justify-content-center items-center pt-1">
            <b-button
              type="reset"
              variant="outline-danger"
              class="mr-1"
              @click="handleCloseModal"
              >Cancelar</b-button
            >
            <b-button type="submit" variant="success" :disabled="invalid"
              >Agregar</b-button
            >
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate"
import { mapGetters, mapMutations, mapActions } from "vuex"

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

export default {
  data() {
    return {
      bookReference: {
        name: "",
        email: "",
        contact: "",
      },
      selectedReference: null,
    }
  },
  props: {
    handleCloseModal: {
      type: Function,
      required: true,
    },
    addReferenceOptionModals: {
      type: Function,
      required: true,
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  computed: {
    ...mapGetters("pos", ["selectedProduct"]),
    ...mapGetters("stores", ["currentStore"]),
  },
  methods: {
    ...mapActions("pos", ["addProductToCart"]),
    ...mapMutations("pos", ["addBookReference", "setBookWithName"]),

    handleAddReference() {
      this.validationStudentForm()
        .then(() => {
          this.setBookWithName(JSON.stringify(this.bookReference))
          this.addProductToCart({
            data: [{ ...this.selectedProduct }],
            reference: this.bookReference,
          })
          this.addBookReference(JSON.stringify(this.bookReference))
          this.addReferenceOptionModals()
          this.bookReference = {
            name: "",
            email: "",
            contact: "",
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error",
              text: "Por favor, completa los campos requeridos",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    },
    validationStudentForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountStudentReferenceRules.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
