<template>
  <b-modal
    id="bookReferencesQuantityModal"
    title="Cantidad de libros"
    hide-header-close
    no-close-on-esc
    no-close-on-backdrop
    size="sm"
  >
    <div class="d-flex justify-content-center align-items-center" v-if="book">
      <b-button
        :disabled="book.length === 1"
        class="units-btn"
        @click="handleDecreaseReferenceQuantity()"
      >
        <i class="fas fa-minus" />
      </b-button>
      <input
        id="weight"
        v-model="book.length"
        type="number"
        class="
          bg-transparent
          display-3
          units
          border-1 border-primary
          rounded
          text-center
        "
        @click="$bvModal.show('cash-numpad')"
      />
      <b-button class="units-btn" @click="handleIncreaseReferenceQuantity()">
        <i class="fas fa-plus" />
      </b-button>
    </div>

    <template #modal-footer>
      <div class="d-flex justify-content-center w-100">
        <b-button
          type="button"
          class="mr-1"
          variant="outline-danger"
          @click="handleRemoveReferenceOption()"
        >
          Borrar
        </b-button>
        <b-button variant="success" @click="handleOk()">
          Aceptar cambios
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from "vuex"

export default {
  props: {
    selectedReference: {
      type: String,
      required: true,
    },
    handleOkModals: {
      type: Function,
      required: false,
    },
    deleteProduct: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters("pos", [
      "booksWithReference",
      "selectedProduct",
      "cart",
      "bookReferences",
    ]),

    book() {
      return this.bookReferences.filter(
        (book) => book === this.selectedReference
      )
    },
  },
  methods: {
    ...mapMutations("pos", [
      "addProductUnits",
      "decreaseReferenceFromBook",
      "addBookReference",
      "decrementProductQuantity",
      "deleteBookReference",
      "setBookWithName",
      "deleteBookName",
      "deleteProductFromCarts",
    ]),
    ...mapActions("pos", ["addProductToCart"]),
    handleOk() {
      this.$bvModal.hide("bookReferencesQuantityModal")
      if (this.handleOkModals) {
        this.handleOkModals()
      }
    },

    handleIncreaseReferenceQuantity() {
      this.setBookWithName(this.selectedReference)
      this.addProductToCart({ data: [{ ...this.selectedProduct }] })
      this.addBookReference(this.selectedReference)
    },
    handleDecreaseReferenceQuantity() {
      const product = this.cart.find(
        (product) => product.id === this.selectedProduct.id
      )
      this.decrementProductQuantity(product)
      this.decreaseReferenceFromBook(this.selectedReference)
    },
    handleRemoveReferenceOption() {
      this.$swal({
        title: "¿Estás seguro?",
        text: "No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No, cancelar!",
        confirmButtonText: "Si, borrarlo!",
        reverseButtons: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
      }).then((result) => {
        if (result.isConfirmed) {
          const product = this.cart.find(
            (product) => product.id === this.selectedProduct.id
          )
          this.book.forEach((book) => {
            this.decrementProductQuantity(product)
            this.deleteProductFromCarts(product)
          })
          this.deleteBookReference(this.selectedReference)
          this.deleteBookName(this.selectedReference)
          this.$bvModal.hide("bookReferencesQuantityModal")
          this.deleteProduct()
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.units-btn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid white;
  margin: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}
.units {
  min-width: 100px;
  color: #000;
}

.dark-layout {
  .units {
    color: #fff;
  }
}
</style>
