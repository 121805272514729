var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{staticClass:"p-1",attrs:{"id":"addReferenceModal","title":"Añadir datos del alumno","hide-footer":"","hide-header-close":"","no-close-on-esc":"","no-close-on-backdrop":""}},[_c('validation-observer',{ref:"accountStudentReferenceRules",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"p-1",on:{"submit":function($event){$event.preventDefault();return _vm.handleAddReference()}}},[_c('validation-provider',{attrs:{"name":"El nombre de la persona a quien va dirigido el producto o servicio es necesario","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-input',{staticClass:"mb-1",attrs:{"state":errors.length > 0 ? false : valid ? true : null,"placeholder":"Nombre completo"},model:{value:(_vm.bookReference.name),callback:function ($$v) {_vm.$set(_vm.bookReference, "name", $$v)},expression:"bookReference.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.currentStore.store_type === 'corporate_education')?_c('validation-provider',{attrs:{"name":"El email de la persona a quien va dirigido el producto o servicio es necesario","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-input',{staticClass:"mb-1",attrs:{"state":errors.length > 0 ? false : valid ? true : null,"placeholder":"Correo"},model:{value:(_vm.bookReference.email),callback:function ($$v) {_vm.$set(_vm.bookReference, "email", $$v)},expression:"bookReference.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),(_vm.currentStore.store_type === 'corporate_education')?_c('validation-provider',{attrs:{"name":"El numero telefonico de la persona a quien va dirigido el producto o servicio es necesario","rules":"required|phone_number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-input',{attrs:{"state":errors.length > 0 ? false : valid ? true : null,"placeholder":"Numero de contacto","maxlength":"10"},model:{value:(_vm.bookReference.contact),callback:function ($$v) {_vm.$set(_vm.bookReference, "contact", $$v)},expression:"bookReference.contact"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),_c('div',{staticClass:"d-flex justify-content-center items-center pt-1"},[_c('b-button',{staticClass:"mr-1",attrs:{"type":"reset","variant":"outline-danger"},on:{"click":_vm.handleCloseModal}},[_vm._v("Cancelar")]),_c('b-button',{attrs:{"type":"submit","variant":"success","disabled":invalid}},[_vm._v("Agregar")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }